import React from "react"
import styled from "@emotion/styled"

import Metadata from "./Metadata"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
`

export default function Layout({ children }) {
    return (
        <Container>
            <Metadata />
            {children}
        </Container>
    )
}
